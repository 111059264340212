import Disclaimer from "../components/Sections/Disclaimer"
import Layout from "../components/Base/Layout"
import React from "react"
import SectionOne from "../components/About/SectionOne"
import SectionTwo from "../components/About/SectionTwo"

function About() {
    
    
    return (
        <Layout>
            <SectionOne></SectionOne>
            <SectionTwo></SectionTwo>
            <Disclaimer></Disclaimer>
        </Layout>
    )
}

export default About
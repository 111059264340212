import React from 'react';
import { IconCashBanknote, IconShieldCheck, IconPackage, IconWorld, IconHeadset } from '@tabler/icons'

  const icons = {
      cash: IconCashBanknote,
      shield: IconShieldCheck, 
      package: IconPackage, 
      world: IconWorld, 
      headset: IconHeadset
  }

  const PostIcon = ({iconName, size}) => {
    const Icon = icons[iconName];
    return <Icon fontSize={size} color="white"/>
  }

export default PostIcon;
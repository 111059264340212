import React from "react"
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  Link
} from '@chakra-ui/react'
import { graphql, useStaticQuery } from "gatsby"

const SectionTwo = () => {
    
    const query = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "about"}) {
        aboutPage {
            content2
            heading2
            }
        }
    }
    `)

    const data = query.wpPage.aboutPage

    return (
        <Box as="section" minH="544px" px={{ base: '0', lg: '12' }} py={{ base: '28', lg: '32' }} background="linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url(/images/about-us.webp);" backgroundPosition="center" backgroundSize="cover" >
            <HStack flexFlow={{base: "column", md: "row"}} justifyContent="center" alignItems="center" w={{base: "100%", md:"726px"}} margin="auto">
                <Image src="https://sphericmed.vercel.app/images/sphericmed-logo-white.svg" pr={{base: "0px", md:"40px"}}></Image>
                <Stack w={{base: "auto", md:"726px"}} pt={{base: '24px', md: '0px'}} textAlign={{base: 'center', md:'left'}} alignItems="flex-start" justifyContent="center">
                    <Heading color="white" fontSize="5xl" fontWeight="700" textAlign={{base: "center", md: "left"}}> { data.heading2 }</Heading>
                    <Text color="white" py="1rem" lineHeight="7">{ data.content2 }</Text>
                    <Link href="/contact-us" _hover={{textDecoration: "none"}} margin={{base: "0px auto !important", md: "0px !important"}}><Button size='lg' variant="solid" bg="blue.500" color="white" margin={{base: '0px auto !important', md: '0 !important'}} >Contact Us</Button></Link>
                </Stack>
            </HStack>
        </Box>
    )
}

export default SectionTwo
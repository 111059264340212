import React from 'react'
import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react'

import { useStaticQuery, graphql } from 'gatsby';
import  PostIcon  from '../Base/Icons'
import { IconCashBanknote, IconShieldCheck, IconPackage, IconWorld, IconHeadset } from '@tabler/icons'

const SectionOne = () => {

    const query = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "about"}) {
        aboutPage {
            heading
            content
            featuresRepeater {
                description
                title
                icon
                }
            }
        }
    }
    `)

    const data = query.wpPage.aboutPage

    const Icons = [{
        name: <IconCashBanknote></IconCashBanknote>,
    }, {
        name: <IconShieldCheck></IconShieldCheck>
        },
        {
        name: <IconPackage></IconPackage>
    },
        {
        name: <IconWorld></IconWorld>
        },
        {
        name: <IconHeadset></IconHeadset>
    }]
    return (
    <Box as="section" bg="white">
        <Container py={{ base: '16', md: '24' }} maxW="7xl">
            <Stack spacing={{ base: '12', md: '16' }}>
                <Stack spacing={{ base: '4', md: '5' }} >
                    <Stack spacing="3">
                        <Text fontSize="sm" color="blue.400">
                            About Us
                        </Text>
                        <Heading color="gray.800" fontSize="4xl">
                            {data.heading}
                        </Heading>
                    </Stack>
                    <Text color="gray.500" fontSize="20px">
                        {data.content}
                    </Text>
                </Stack>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
                        {data.featuresRepeater.map((feature: any) => (
                        <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
                            <Square size={{ base: '10', md: '12' }} bg="blue.400" color="white" borderRadius="lg">
                               <PostIcon iconName={feature.icon} size="24px"/>
                                </Square>
                            <Stack spacing={{ base: '1', md: '2' }} flex="1">
                                <Text fontSize="20px" fontWeight="500" color="gray.800" >
                                    {feature.title} 
                                </Text>
                                <Text color="gray.500" fontSize="md" lineHeight="6">{feature.description}</Text>
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
        </Box>
    )
}

export default SectionOne
